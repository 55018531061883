<template>
  <div class="pl-8 pr-8">
    <div class="ml-3 mr-3">
      <v-row>
        <v-col class="mt-14 pb-5 pl-0 pr-0 text-left" cols="12">
          <v-layout justify-center>
            <img height="70" class="ml-2" :src="infoApp.logo" />
          </v-layout>
        </v-col>
      </v-row>
    </div>

    <!-- <v-col class="my-3 py-0" cols="6">
        <p class="linguo__text mb-0 text-left">Escriba su texto</p>
      </v-col> -->
    <!-- <v-col class="my-0 py-0" cols="6">
        <v-card class="rounded-lg" outlined>
          <vue-editor
            :editor-toolbar="customToolbar"
            ref="editor"
            v-model="html"
          ></vue-editor>
        </v-card>
      </v-col> -->

    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <p class="linguo__text mb-0 text-left">
              Registre la siguiente información
            </p>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              prepend-inner-icon="mdi-filter-outline"
              v-model="form.what"
              :items="listWhatTactic"
              label="¿Qué tactica quieres hacer?"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              prepend-inner-icon="mdi-filter-outline"
              v-model="form.where"
              :items="listWhereTactic"
              label="¿Dónde quieres hacer la tactica?"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              prepend-inner-icon="mdi-filter-outline"
              v-model="form.tone"
              :items="listTone"
              label="¿Cuál es el tono?"
            ></v-autocomplete>
            <v-textarea
              rows="4"
              v-model="form.product"
              label="Describe el producto o servicio que ofrece tu empresa"
            ></v-textarea>
            <v-textarea
              rows="4"
              v-model="form.audiencie"
              label="Cuéntanos un poco sobre la audiencia a la que le quieres dirigir el mensaje"
            ></v-textarea>
            <v-textarea
              rows="4"
              v-model="form.message"
              label="¿Cuál es el mensaje que quieres dar?"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-layout justify-end>
          <v-btn @click="send()" rounded color="#466be3" class="mt-4" dark>
            <strong> CONSULTAR </strong>
          </v-btn>
        </v-layout>
      </v-col>
      <v-col cols="6">
        <p class="linguo__text mb-3 text-left">
          Acá esta su resultado

          <v-btn
            v-if="linguoId != null"
            fab
            small
            :color="color.btnLike"
            class="mx-1"
            elevation="0"
            @click="like()"
          >
            <img :src="color.srcLike" />
          </v-btn>

          <v-btn
            v-if="linguoId != null"
            small
            fab
            :color="color.btnDis"
            class="mx-1"
            elevation="0"
            @click="disLike()"
          >
            <img :src="color.srcDis" />
          </v-btn>
        </p>

        <v-card class="px-8 py-8 text-left"  outlined height="680" >
          <div v-html="html2">

          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row class="mt-5" justify="center">
      <v-col cols="3">
        <v-btn
          @click="send('Redacción de estilo')"
          rounded
          block
          color="#466be3"
          class="mt-4"
          dark
        >
          <strong> Redacción de estilo </strong>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          @click="send('Redacción de email')"
          rounded
          block
          color="#466be3"
          class="mt-4"
          dark
        >
          <strong> Redacción de email </strong>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          @click="send('Lista de ideas')"
          rounded
          block
          color="#466be3"
          class="mt-4"
          dark
        >
          <strong> Lista de ideas </strong>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          @click="send('Formato brief')"
          rounded
          block
          color="#466be3"
          class="mt-4"
          dark
        >
          <strong> Formato brief </strong>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          @click="send('mejorar respuesta')"
          rounded
          block
          color="#466be3"
          class="mt-4"
          dark
        >
          <strong> Mejorar respuesta </strong>
        </v-btn>
      </v-col>
    </v-row> -->
    <div class="mt-10">
      <v-layout justify-end>
        <img height="40" src="../../assets/img/Linguo.png" />
      </v-layout>
    </div>

    <v-dialog v-model="modal.disLike" max-width="500">
      <v-card class="px-5 py-5" outlined style="border-radius: 15px">
        <v-card-title class="text-center">
          ¿No le gusto la respuesta?
        </v-card-title>
        <v-card-text>
          <v-textarea label="ingrese aquí su retroalimentación"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                rounded
                block
                outlined
                color="#3957B9"
                @click="modal.disLike = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn rounded block color="#3957B9" @click="modal.disLike" dark>
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Procesando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import { VueEditor } from "vue2-editor";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    VueEditor,
    Alert,
  },
  data() {
    return {
      id: this.$route.params.id,
      origin: window.location.origin,
      linguoId: null,
      alert: {
        txt: "",
        title: "",
        type: "",
        redirect: "",
        open: false,
      },
      modal: {
        disLike: false,
      },
      infoApp: {
        logo: null,
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
      ],
      html: "",
      html2: "",
      token: {
        prompt_tokens: "",
        completion_tokens: "",
      },
      loading: false,
      token1: null,
      name: "",
      infoToken: {
        document: "",
      },
      listWhereTactic: ["TikTok", "Web", "Instagram"],
      listWhatTactic: [
        "Ideas de contenido",
        "Artículo",
        "Ideas para hacer contenidos con influenciadores",
      ],
      listTone: ["Divertido", "Atrevido", "Emocional"],
      form: {
        tone: "",
        what: "",
        where: "",
        product: "",
        audiencie: "",
        message: "",
      },
      color: {
        btnLike: "#0000",
        btnDis: "#0000",
        srcLike: origin + "/icon/like.svg",
        srcDis: origin + "/icon/dislike.svg",
      },
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    clearLike() {
      this.color.btnLike = "#0000";
      this.color.srcLike = origin + "/icon/like.svg";
    },
    clearDisLike() {
      this.color.btnDis = "#0000";
      this.color.srcDis = origin + "/icon/dislike.svg";
    },
    like() {
      this.clearDisLike();
      if (this.color.btnLike != "#466BE3") {
        var data = {
          like: 1,
          linguo_id: this.linguoId,
        };
        this.loading = true;
        Api.Auth()
          .likeOrDislike(this.token1, data)
          .then((resP) => {
            this.color.btnLike = "#466BE3";
            this.color.srcLike = this.origin + "/icon/like-while.svg";
            this.loading = false;
            this.alert.open = true;
            this.alert.title = "Excelente";
            this.alert.txt = resP.data.message;
            this.alert.type = "success";
            console.log("se registró el dato");
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    disLike() {
      this.clearLike();
      this.modal.disLike = true;
      if (this.color.btnDis != "#466BE3") {
        var data = {
          like: 0,
          feedback: this.feedback,
          linguo_id: this.linguoId,
        };
        if (this.$refs.form.validate()) {
          this.loading = true;
          Api.Auth()
            .likeOrDislike(this.token1, data)
            .then((resP) => {
              this.loading = false;
              console.log("se registró el dato");
              this.color.btnDis = "#466BE3";
              this.color.srcDis = this.origin + "/icon/dislike-while.svg";
              this.modal.disLike = false;
              this.alert.open = true;
              this.alert.title = "Excelente";
              this.alert.txt = resP.data.message;
              this.alert.type = "success";
            })
            .catch((error) => {
              this.loading = false;

              console.log(error);
            });
        }
      }
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token1 = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    send() {
      var p =
        "Crear: " +
        this.form.what +
        " \nPara: " +
        this.form.where +
        " \nNuestro producto es: " +
        this.form.product +
        " \nCreados para: " +
        this.form.audiencie +
        " \nQueremos comunicar que: " +
        this.form.message +
        " \nTono: " +
        this.form.tone;
      console.log(p);
      // return false;
      this.loading = true;
      var data = {
        prompt: p
      };
      Api.Auth()
        .spellingBrands(data)
        .then((resp) => resp.json())
        .then((res) => {
          this.loading = false;
          console.log(res);
          this.html2 = res.choices[0].message.content;
          this.token = res.usage;
          var dataReport = {
            completion_tokens: res.usage.completion_tokens,
            prompt_tokens: res.usage.prompt_tokens,
            total_tokens: res.usage.total_tokens,
            original_message: p,
            new_message: res.choices[0].message.content,
          };
          Api.Auth()
            .saveSpelling(this.token1, dataReport)
            .then((resP) => {
              this.linguoId = resP.data.data;
              console.log("se registró el dato");
              this.alert.open = true;
              this.alert.title = "Excelente";
              this.alert.txt = resP.data.message;
              this.alert.type = "success";
            })
            .catch((error) => {
              console.log(error);
              this.alert.open = true;
              this.alert.title = "¡Oh no!";
              this.alert.txt = "Hubo un error cargando la respuesta";
              this.alert.type = "error";
            });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getDetailsApp() {
      Api.noAuth()
        .getDetailsApp(this.id)
        .then((resp) => resp.json())
        .then((res) => {
          this.loading = false;
          console.log(res.data);
          this.infoApp.logo = res.data[0].logo;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDetailsApp();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.linguo__title-text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.linguo__subtitle {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}

.linguo__text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 59px;
  color: #353535;
}
</style>
